<template>
    <div class="init">
        <el-form :model="fromValue" label-width="100px" class="elForm" :rules="rules" ref='form_' label-position="top">
            <el-form-item :label="$t('contra.text2')" prop="protocolType">
                <el-radio-group v-model="fromValue.protocolType" size="large" @change="changeProtocolType">>
                    <el-radio-button :label="1">FT</el-radio-button>
                    <el-radio-button :label="2">NFT</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('contra.text4')" prop="contractName">
                <el-input v-model="fromValue.contractName" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('contra.text5')" prop="chain_protocol">
                <el-cascader v-model="fromValue.chain_protocol" :options="chain_protocolArray" @change="handleChange" :disabled='!fromValue.protocolType'/>
            </el-form-item>
            <el-form-item :label="$t('contra.text6')" prop="contractType">
                <!-- <el-select v-model="fromValue.contractType" :placeholder="$t('inputTit.xuanze')">
                    <el-option :label="$t('contra.text7')" :value="1" />
                </el-select> -->
                <el-radio-group v-model="fromValue.contractType" size="large">
                    <el-radio-button label="1">{{$t('contra.text7')}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('contra.text14')" prop="contractClassName">
                <el-input v-model="fromValue.contractClassName" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item label="Gas Limit" prop="deployGasLimit">
                <el-input v-model="fromValue.deployGasLimit"  type="number" :placeholder="$t('inputTit.shuru')" />
            </el-form-item>
            <el-form-item :label="$t('contra.name1')" prop="mintType">
                <el-radio-group v-model="fromValue.mintType" size="large">
                    <el-radio-button :label="1">{{$t('contra.name1_1')}}</el-radio-button>
                    <el-radio-button :label="2">{{$t('contra.name1_2')}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('contra.name2')" prop="supplyType">
                <el-radio-group v-model="fromValue.supplyType" size="large">
                    <el-radio-button :label="1">{{$t('contra.name2_1')}}</el-radio-button>
                    <el-radio-button :label="2">{{$t('contra.name2_2')}}</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    // import { userStore } from '@/store/user'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        templateId:'',chain_protocol:'',
        protocolType: '1',contractName:'',chain:'',protocol:'',
        deployGasLimit:'',contractClassName:'',contractType:'1',
        mintType: 1,supplyType: 1
    })
    // const protocolTypeList = ref([{ label: $t('contra.text3'), value: 0 }, 
    //     { label: 'FT', value: 1 },
    //     { label: 'NFT', value: 2 }
    // ])

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)
    const chain_protocolArray = ref([])

    const emit = defineEmits(['successFun'])

    const handleChange = (e)=>{
        fromValue.chain = e[0]
        fromValue.protocol = e[1]
    }
    const changeProtocolType = ()=>{//
        Api_.getCoinOrxie({
            protocolType: fromValue.protocolType,
            status: 1
        }).then(res=>{
            chain_protocolArray.value = dicFormatter(res.data)
        })
        fromValue.chain = ''
        fromValue.protocol = ''
    }
    const dicFormatter = (e)=>{
        let obj_1 = []
        for(let item of e){
            if(item){
                let obj_ = {
                    label: item.chain,
                    value: item.chain,
                }
                if(item.chainProtocolInfos.length > 0){
                    let obj_c = []
                    for(let items of item.chainProtocolInfos){
                        obj_c.push({
                            label: items.protocol,
                            value: items.protocol
                        })
                    }
                    obj_.children = obj_c
                    obj_1.push(obj_)
                }
            }
        }
        return obj_1
    }
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.templateId
                    Api_.addContractObj(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateContractObj(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj)=>{//初始化数据
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
            fromValue.chain_protocol = [fromValue.chain , fromValue.protocol]
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
            fromValue.protocolType = 1
            fromValue.mintType = 1
            fromValue.supplyType = 1
        }
        changeProtocolType()
    }
    defineExpose({init_,completeFun})
</script>